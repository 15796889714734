@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/functions.scss';
@import '../../../assets/scss/mixins/flexbox.scss';
@import '../../../assets/scss/mixins/responsive.scss';

body {
  overflow-x: hidden;
}

.wrapper {
  background-color: $clr-light-grey;
  width: 100%;
  height: size-rem(1037px);
  position: relative;
  padding: size-rem(143px) size-rem(80px) size-rem(145px);
  display: flex;
  flex-direction: column;

  @include vp--740 {
    height: size-rem(1328px, true);
    padding: size-rem(60px, true) size-rem(22px, true);
  }
}

.mapBox {
  overflow: hidden;
  width: 100vw;
  overflow-x: hidden;
}

.map {
  width: size-rem(981px);
  position: absolute;
  right: 0;
  top: 0;

  @include vp--740 {
    transform: translateX(-50%);
    right: auto;
    left: 50%;
    width: 100%;
    height: size-rem(731px, true);
    top: size-rem(372px, true);
    object-fit: cover;
    object-position: 55% 50%;
  }
}

.title {
  position: relative;
  z-index: 1;
  margin-left: size-rem(-11px);
  transition: 0.5 ease-in-out;
  height: size-rem(268px);

  @include vp--740 {
    height: size-rem(72px, true);
    margin-bottom: size-rem(30px, true);
  }

  &_box {
    position: absolute;
  }

  & p {
    font-family: $main-font-family;
    font-size: size-rem(85px);
    font-weight: 350;
    line-height: size-rem(76.5px);
    letter-spacing: -0.02em;
    text-align: left;
    color: $clr-dark-black;

    @include vp--740 {
      font-size: size-rem(40px, true);
      line-height: size-rem(36px, true);
    }
  }

  & p:nth-child(2) {
    margin-left: size-rem(84px);
    margin-left: size-rem(240px);
    @include vp--740 {
      margin-left: 0;
    }
  }
  & p:nth-child(3) {
    margin-left: size-rem(84px);
    @include vp--740 {
      margin-left: 0;
    }
  }
}

.description {
  width: size-rem(474px);
  margin-left: (72px);
  position: relative;
  height: size-rem(268px);
  margin-bottom: size-rem(76px);

  @include vp--740 {
    width: 100%;
    margin-left: 0;
  }

  &_box {
    position: absolute;
  }

  & p {
    color: $clr-dark-black;
    font-family: $primary-font-family;
    font-size: size-rem(18px);
    font-weight: 400;
    line-height: size-rem(27px);
    letter-spacing: -0.02em;
    text-align: left;

    @include vp--740 {
      font-size: size-rem(16px, true);
      font-weight: 400;
      line-height: size-rem(22.4px, true);
      letter-spacing: -0.02em;
      text-align: left;
    }
  }
}

.link {
  color: $clr-dark-black;
  text-transform: uppercase;
  font-family: $main-font-family;
  font-size: size-rem(16px);
  font-weight: 350;
  line-height: size-rem(19.2px);
  text-align: left;
  padding: size-rem(26px) size-rem(30px);
  border: 1px solid $clr-grey;
  display: inline-block;
  border-radius: size-rem(60px);
  margin-top: size-rem(60px);
  transition: all 0.5s ease;
  background-color: $clr-light-grey;

  @include vp--740 {
    font-size: size-rem(16px, true);
    padding: size-rem(16px, true) size-rem(20px, true);
    border-radius: size-rem(60px, true);
    line-height: size-rem(19.2px, true);
    margin-top: size-rem(38px, true);
  }

  &:hover {
    background-color: $clr-dark-black;
    color: $clr-white;
    border: 1px solid $clr-dark-black;

    @include vp--740 {
      background-color: $clr-light-grey;
      border: 1px solid $clr-grey;
      color: $clr-dark-black;
    }
  }
}

.bottomInfo {
  position: relative;
  z-index: 1;

  @include vp--740 {
    top: size-rem(800px, true);
  }

  &_box {
    position: absolute;
    display: flex;
    gap: size-rem(80px);
    list-style-type: none;

    @include vp--740 {
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  &_piece {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include vp--740 {
      width: size-rem(170px, true);
    }
  }
  &_time {
    font-family: $main-font-family;
    font-size: size-rem(80px);
    font-weight: 350;
    line-height: size-rem(90.4px);
    letter-spacing: -0.02em;
    text-align: left;
    text-wrap: nowrap;

    @include vp--740 {
      font-size: size-rem(54px, true);
      line-height: size-rem(54px, true);
    }
  }
  &_description {
    text-wrap: nowrap;
    font-family: $main-font-family;
    font-size: size-rem(20px);
    font-weight: 350;
    line-height: 26px;
    text-align: center;

    @include vp--740 {
      font-size: size-rem(16px, true);
      font-weight: 350;
      line-height: size-rem(20.8px, true);
      text-align: center;
      text-wrap: balance;
    }
  }
}
